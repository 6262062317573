import React from 'react';
import DOMPurify from 'dompurify';
import { Helmet } from 'react-helmet';

import Button from '../../components/Button';
import Footer from '../../components/Footer';
import Navigation from '../../components/Navigation';
import { OrangeLink } from '../../components/Link';
import Page from '../../components/Page';
import Section from '../../components/Section';
import Theme from '../../components/Theme';
import { themeSecondary } from '../../styles/constants';
import {
  StyledLink,
  ButtonSection,
  ButtonBetaText,
  ButtonPlacementSection,
  HeaderHeading,
  NumberList,
  TermsListItem
} from './style';

const ModerationPolicy = () => {
  const paragraphs = [
    `<p><b>Introduction:</b></p>
        <p>1.1 This Moderation Policy sets out the way Karista Pty Ltd (ACN 614 763 076) (“Karista”) manages the publication of reviews and ratings on its Website and other social media channels.</p>
        <p>1.2 The term “Service Provider” used in this Moderation Policy has the same meaning given to it in the Terms and Condition available at 
        <a href="https://www.karista.com.au/static/providers-terms-and-conditions-karista-website.pdf">https://www.karista.com.au/static/providers-terms-and-conditions-karista-website.pdf</a>. <br>
        The term “Website” used in the Moderation Policy has the meaning given in the Service Provider Terms and Conditions available at 
        <a href="https://www.karista.com.au/static/providers-terms-and-conditions-karista-website.pdf">https://www.karista.com.au/static/providers-terms-and-conditions-karista-website.pdf</a>.</p> <br>`,
    `<p><b>Verification	of Reviews and Ratings:</b></p>
        <p>2.1 Karista® will use commercially reasonable endeavours	to ensure and verify the validity of reviews and ratings such as:</p>
        <ul style="list-style-type: none; padding-left: 0.5em;">
        <li style="margin-left: 1.5em;">(a) allowing a customer who has engaged a Service Provider to review and rate that particular Service Provider to assist other customers in selecting appropriate Service Providers for their own needs;</li>
        <li style="margin-left: 1.5em;">(b) displaying the number of reviews that have been used in calculating the average rating on a Service Provider; and</li>
        <li style="margin-left: 1.5em;">(c) displaying reviews and ratings in the order that they are received.</li>
        </ul> <br>`,
    `<p><b>Moderation Principles:</b></p>
        <p>3.1 Karista’s intervention in moderation will be	as minimal as possible to maintain the credibility of reviews and ratings and their	value for both consumers and Service Providers.</>
        <p>3.2 Only	a customer (or its representative) who has previously engaged a	Service	Provider may review	and	rate the Service Provider. <p>
        <p>3.3 Service Providers should	regularly assess the reviews and ratings on	their Karista profile and report any suspected breach of this Moderation Policy	for	investigation by 
        Karista by sending an email	to <a href="mailto:provider-enquiries@karista.com.au?Subject=Provider Enquires">provider-enquiries@karista.com.au</a> <p><br>`,
    `<p><b>Review and Ratings Content - Moderation Guidelines:</b></p>
        <p>4.1 Karista may alter, reject or	delete any content that	it knows or reasonably believes:</p>
        <ul style="list-style-type: none; padding-left: 0.5em;">
        <li style="margin-left: 1.5em;">(a) is false or	misleading;</li>
        <li style="margin-left: 1.5em;">(b) is offensive, defamatory or irrelevant;</li>
        <li style="margin-left: 1.5em;">(c) is commercial, advertising,	solicitation or	spam;</li>
        <li style="margin-left: 1.5em;">(d) is a personal attack on	any	Service	Provider or	staff or contains personal information;</li>
        <li style="margin-left: 1.5em;">(e) is discriminatory of people’s gender, race,	 religion, culture, sexual preference, appearance or background;</li>
        <li style="margin-left: 1.5em;">(f) contains explicit or inappropriate content;	or</li>
        <li style="margin-left: 1.5em;">(g) contains ‘false’ reviews by parties who	have no	genuine	recipient experience of a Service Provider’s care.</li>
        </ul>
        <p>4.2 Karista may take action to prevent the participation	of the author of such content in its platform and may, in certain cases, report	the	issue to the relevant authorities.</p><br>`,
    `<p><b>Moderation Process:</b></p>
        <p>5.1 Karista will	use	commercially reasonable	endeavours:</p>
        <ul style="list-style-type: none; padding-left: 0.5em;">
        <li style="margin-left: 1.5em;">(a) to check all reviews and ratings prior to publishing;</li>
        <li style="margin-left: 1.5em;">(b) to refer reviews with inappropriate	content	to the reviewer for	editing	prior to publishing;</li>
        <li style="margin-left: 1.5em;">(c) to change the names	within reviews to first	initial	and “*”	to replace other letters;</li>
        <li style="margin-left: 1.5em;">(d) if the validity	of the relationship with a Service Provider has been questioned, to	require	the	reviewer to	confirm	their relationship to a	Service	Provider; and</li>
        <li style="margin-left: 1.5em;">(e) to provide Service Providers with an opportunity to provide a response to a	customer’s review:</li>
        <ul style="list-style-type: none; padding-left: 4em;">
        <li style="margin-left: 1.5em;">(i) which has generated	a rating under 3 stars; or </li>
        <li style="margin-left: 1.5em;">(ii) if	negative comments have been	received in	relation to	the	Service	Provider, 
        provided that the Service Provider has completed the Provider Details form to update their contact 
        details	on the Website.</li></ul>
        </ul> <br>`,
    `<p><b>Variation</b></p>
        <p>6.1 Karista reserves	the	right to review	and	change any of the Moderation Policy	by updating	this page. Any changes to the Moderation Policy	take immediate effect 
        from the date of their publication.</p><br>`
  ];

  const handleClick = () => {
    // Scroll to the top of the page
    window.scrollTo(0, 0);
  };

  const SupportButton = () => (
    <ButtonPlacementSection>
      <ButtonSection>
        <Button>
          <StyledLink onClick={handleClick} to="/">
            <ButtonBetaText>Search for supports now</ButtonBetaText>
          </StyledLink>
        </Button>
      </ButtonSection>
    </ButtonPlacementSection>
  );

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <Theme theme={themeSecondary}>
        <Navigation>
          <OrangeLink border to="/">
            Back to search
          </OrangeLink>
        </Navigation>
        <Page>
          <Section>
            <ButtonPlacementSection>
              <HeaderHeading>Karista Moderation Policy</HeaderHeading>
            </ButtonPlacementSection>
            <NumberList>
              {paragraphs.map(paragraph => (
                <TermsListItem
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(paragraph)
                  }}
                />
              ))}
            </NumberList>
            <p>
              Please contact Karista if you have any questions regarding this
              Moderation Policy
            </p>
            <SupportButton />
          </Section>
        </Page>
        <Footer />
      </Theme>
    </>
  );
};
export default ModerationPolicy;
