import styled from "styled-components";
import Section from "../../components/Section";
import { AlphaHeading, GammaHeading } from "../../components/Typography";
import {colors} from "../../styles/constants";
import mediaQueries from "../../styles/mediaQueries";
import IconLock from "../../components/WhatWeDoCard/icon-lock.svg";

const cardStackBreakpoint = '720px';

export const InfoText = styled.span`
  line-height: 26px;
`;

export const BlackInfoText = styled(InfoText)`
  color: ${colors.black};
  display: block;
  font-size: 90%;
`;

export const HeaderSection = styled(Section)`
  text-align: center;
  padding-top: 24px;
  @media (max-width: ${cardStackBreakpoint}) {
    width: auto;
  };
`;

export const HeaderHeading = styled(AlphaHeading)`
  margin-bottom: 12px;
`;

export const CardsSection = styled(Section)`
  display: flex;
  justify-content: space-between;
  padding-bottom: 32px;
  
  @media (max-width: ${cardStackBreakpoint}) {
    flex-direction: column;
    align-items: center;
  }
`;

export const CardSpacer = styled.div`
  max-width: 480px;
  min-width: 200px;
  margin: 0 12px;
  flex-grow: 1;
  flex-basis: 0;
  @media (max-width: ${cardStackBreakpoint}) {
    &:not(:last-of-type) {
      margin-bottom: 48px;
    }
  }
`;

export const Card = styled.div`
  color: ${colors.black};
  background: ${colors.white};
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;
  border-radius: 10px;
  @media (max-width: ${cardStackBreakpoint}) {
    padding: 20px 40px;
  }
`;

export const CardTopAnchor = styled.div`
  position: relative; 
  width: 0; 
  height: 0;
`;

export const CardTopIcon = styled.div`
  font-weight: 400;
  font-size: 20px;
  color: ${colors.white};
  background: ${colors.orange};
  border-radius: 50%;
  position: absolute;
  top: -36px;
  left: -18px;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CardIconContainer = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;
  @media (max-width: ${cardStackBreakpoint}) {
    margin-top: 0;
  }
`;

export const CardIcon = styled.img`
  width: 60px;
  @media (max-width: ${cardStackBreakpoint}) {
    display: none;
  }
`;

export const CardOrangeText = styled(InfoText)`
  color: ${colors.orange};
  font-weight: 400;
`;

export const CardInfoText = styled(BlackInfoText)`
  padding: 5px;
`;

export const ConfidentialitySection = styled(Section)`
  padding-top: 36px;
  padding-bottom: 56px;
  display: flex;
  @media (max-width: ${cardStackBreakpoint}) {    
    display: block;
    text-align: center;
  }
`;

export const ConfidentialityIcon = styled.img.attrs({src: IconLock})`
  padding-right: 16px;
  @media (max-width: ${cardStackBreakpoint}) {
    padding-right: 0;
    padding-bottom: 16px
  }
`;

export const CallUsSection = styled.section`
  margin: 0;
  text-align: left;
  padding-bottom: 56px;
  
  @media (max-width: ${cardStackBreakpoint}) {
    text-align: center;
  }
`;

export const CallUsPhoneNumber = styled(GammaHeading).attrs({
  as: 'a',
  href: 'tel:+61485972676',
})`
  color: ${colors.orange};
  margin-bottom: 8px;
  text-decoration: none;
`;

export const CallUsHeading = styled(GammaHeading)`
  color: ${colors.purple};
  line-height: 28px;
  margin: 0;
`;

export const CallUsHeaderSection = styled(Section)`
  text-align: left;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 0px;

  @media (max-width: ${cardStackBreakpoint}) {
    flex-direction: column;
    text-align: center;
  }
`;

export const ClientServiceCircleIcon = styled.div`
  margin-bottom: 12px;
  margin-right: 16px;

  @media (max-width: ${cardStackBreakpoint}) {
    margin: 0;
  }
`;

export const StyledTitle = styled(AlphaHeading)`
  margin-right: 24px;
  margin-left: 24px;
  margin-bottom: 10px;
  font-size: 30px;
  line-height: 36px;

  @media (max-width: ${cardStackBreakpoint}) {
    line-height: 36px;
    margin-top: 0px;
    margin-bottom: 32px;
    margin-right: 0px;
    margin-left: 0px;
    font-size: 24px;
  };
`;

export const CampaignHeaderSection = styled(Section)`
  text-align: left;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 0px;
  margin-bottom: 30px;
  height: 150px;

  @media (max-width: ${cardStackBreakpoint}) {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: flex-start;
    margin-bottom: 30px;
    height: 265px;
  }
`;

export const CampaignIcon = styled.div`
  display: flex;
  justify-content: flex-end;
  height: 175px;
  width: 200px;

  @media (max-width: ${cardStackBreakpoint}) {
    align-self: flex-start;
    margin-top: -30px;
  }
`;

export const VerticalLogoSection = styled.img`
  height: 108px;
  margin-bottom: 16px;
  @media (max-width: ${cardStackBreakpoint}) {
    margin-bottom: 0px;
    margin-left: 5px;
  };
`;

export const CampaignHeading = styled(GammaHeading)`
  margin: 0;
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 32px;
`;

export const Banner = styled.div`
  color: ${colors.darkPurple};
  background: ${colors.orange};
  text-align: center;
  padding: 10px 10px;
`;

export const HeaderText = styled(BlackInfoText)`
  line-height: 28px;
  padding-bottom: 30px;
  padding-top: 0px;
  margin: 0;
  @media (max-width: ${cardStackBreakpoint}) {
    padding: 0;
     padding-bottom: 24px;
  };
`;

export const SearchSection = styled.section`
  margin: 0;
  padding-bottom: 56px;

  @media ${mediaQueries.desktop} {
    max-width: 800px;
    margin: 0 auto;
  }
`;

export const BottomSearchSection = styled.section`
  margin: 0;
  padding-top: 48px;
  padding-bottom: 56px;

  display: flex;
  flex-direction: column;
  align-items: center; 
  justify-content: center;

  @media ${mediaQueries.desktop} {
    max-width: 800px;
    margin: 0 auto;
}
`;

export const InformationSection = styled(Section)`
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 0px;
  margin-bottom: 0px;
`;

export const InfoContentText = styled(BlackInfoText)`
  margin-bottom: 24px;

  > div {
    text-align: left;
    columns: 2;
    width: 450px;
    margin-left: 200px;

    @media (max-width: ${cardStackBreakpoint}) {
      columns: 1;
      display: flex;
      justify-content: center;
      margin-left: 50px;
      width: 200px;

    }
  }
`;

export const ParagraphHeaderText = styled(GammaHeading)`
  color: ${colors.purple};
  line-height: 28px;
  margin-bottom: 12px;
`;

export const MobileImageSection = styled.section`
  display: flex;
  justify-content: space-between;
  width: 95%;
`;

export const CallLink = styled.a`
  color: ${colors.darkPurple};
  text-decoration: none;
`;

export const CallLinkBlack = styled.a`
  color: ${colors.black};
  text-decoration: none;
`;

export const FundingPopUp = styled.div`
  font-size: 28px;
  color: darkPurple;
  margin-top: 12px;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const FundingInformationLink = styled.a`
  color: ${colors.white};
  font-size: 20px;
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 10px;
`;

export const SeparateFundingInformationLink = styled.a`
  color: ${colors.white};
  font-size: 20px;
  display: flex;
  justify-content: center;
  text-align: center;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  color: white;
  font-size: 20px;
  cursor: pointer;
`;

export const FundingPopUpSection = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-contet: center;

  @media (max-width: ${cardStackBreakpoint}) {
    flex-direction: column;
  };
`;