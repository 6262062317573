import React, {ReactNode} from 'react';
import Theme from '../../../../components/Theme';
import {themeLightGrey} from "../../../../styles/constants";
import WhatWeDoCard from "../../../../components/WhatWeDoCard";

import {
    BlackInfoText,
    ConfidentialityIcon,
    ConfidentialitySection,
    HeaderHeading,
    HeaderSection,
    HeaderText
} from "./style";

export default (props) => (
    <Theme theme={themeLightGrey}>
        <HeaderSection>
            <HeaderHeading>How does Karista work?</HeaderHeading>
            <HeaderText>
                Karista provides a <b>free</b>, independent service connecting you with disability and home care
                services, therapists and support workers based on your personal needs and goals. Our Client
                Services team are experienced in finding and connecting NDIS and Home Care Package (HCP)
                participants to supports with availability.
            </HeaderText>
        </HeaderSection>

        <WhatWeDoCard />
        <ConfidentialitySection>
            <ConfidentialityIcon alt="Lock icon" />
            <BlackInfoText>
                <b>Confidentiality</b>: Your details are not shared until you give us permission,
                so you know that your private information is kept confidential.
            </BlackInfoText>
        </ConfidentialitySection>
    </Theme>
);
