/* eslint-disable no-confusing-arrow */

import React, { useState } from 'react';
import styled from 'styled-components';
import calculateRem from '../../styles/calculateRem';
import { colors } from '../../styles/constants';
import mediaQueries from '../../styles/mediaQueries';
import SocialLink from '../SocialLink';

const StyledFooter = styled.footer`
  background-color: ${colors.darkPurple};
  padding: ${calculateRem(12)};
  text-align: center;
`;

const Anchor = styled.a`
  color: ${colors.white};
  padding: 0.75rem 0;
  margin: 0 0.75rem;
  text-decoration: underline;
  cursor: pointer;
`;

const FooterNavLink = styled.div`
  display: block;
  ${mediaQueries.mobile`
    display: flex;
    flex-direction: column;
    `};
`;

interface StyledSpanProps {
  block?: boolean,
}

// eslint disabled no-confusing-arrow rule for styled-components to use props
export const StyledSpan = styled.span<StyledSpanProps>`
  padding: 0 ${calculateRem(2)};
  font-size: ${calculateRem(16)};
  color: ${colors.white};
  display: ${props => (props.block ? 'block' : 'inline')};
`;

const ContactDropdownContainer = styled.div`
  position: relative;
`;

const ContactDropdown = styled.div`
  position: absolute;
  top: ${calculateRem(-156)};
  left: 62%;
  background-color: ${colors.darkPurple};
  padding: ${calculateRem(12)};
  border-radius: ${calculateRem(5)};
  box-shadow: 0 0 ${calculateRem(10)} rgba(0, 0, 0, 0.2);
  text-align: left;
  width: 270px;
  ${mediaQueries.mobile`
    position: relative;
    top: auto;
    left: 12%;
    `};
`;

const ListItem = styled.li`
  color: ${colors.white};
`;

const footerContent = [
  {
    link: '/terms-and-conditions',
    label: 'Terms & Conditions',
    target: '_self'
  },
  {
    link: '/privacy-policy',
    label: 'Privacy policy',
    target: '_self'
  },
  {
    link: '/moderation-policy',
    label: 'Moderation policy',
    target: '_self'
  },
  {
    link: '/admin/login',
    label: 'Provider admin',
    target: '_self'
  },
];

const Footer = ({ children }) => {
  const [showContactDropdown, setShowContactDropdown] = useState(false);

  const toggleContactDropdown = () => {
    setShowContactDropdown(!showContactDropdown);
  };

  const openChat = e => {
    e.preventDefault();
    var chatButton = document.getElementById('esw-fab');
    if (chatButton) {
      chatButton.click();
    }
  }

  return (
    <StyledFooter>
      {children}
      <FooterNavLink>
        {footerContent.map(content => (
          <Anchor
            key={`footer-link-${content.link}`}
            href={content.link}
            target={content.target}
          >
            <StyledSpan key={`footer-content-${content.label}`}>
              {content.label}
            </StyledSpan>
          </Anchor>
        ))}
        <Anchor>
        <StyledSpan onClick={toggleContactDropdown}>Contact us</StyledSpan>
        {showContactDropdown && (
          <ContactDropdownContainer>
          <ContactDropdown>
            <ul style={{ listStyleType: 'disc', paddingLeft: '20px', color: 'white' }}>
              <ListItem>
                <StyledSpan block>
                  <Anchor href="tel:+61485972676"> Call us on 0485 972 676, or</Anchor>
                </StyledSpan>
              </ListItem>
              <ListItem>
                <StyledSpan block>
                  <Anchor href="mailto:info@karista.com.au?Subject=Contact%20from%20karista.com.au">
                  Email us, or
                  </Anchor>
                </StyledSpan>
              </ListItem>
              <ListItem>
                <StyledSpan block>
                  <Anchor href = "" onClick={openChat}>
                  Start a Live chat here
                  </Anchor>
                </StyledSpan>
              </ListItem>
            </ul>
          </ContactDropdown>
          </ContactDropdownContainer>
        )}
        </Anchor>
      </FooterNavLink>
      <SocialLink />
      <StyledSpan block>
        Copyright &#169; 2018 Karista Pty Ltd. All rights reserved. ABN 92614763076
      </StyledSpan>
    </StyledFooter>
  );
};

Footer.defaultProps = {
  children: null
};

export default Footer;
