import React, {ReactNode} from 'react';
import DOMPurify from 'dompurify';

import Theme from '../../../../../components/Theme';
import {themeLightGrey} from "../../../../../styles/constants";
import EnquireButton from '../../EnquireButton';

import {
    HeaderHeading,
    HeaderSection,
    ServiceCard,
    CardSpacer,
    CardsSection,
    BlackInfoText
} from "./style";

import {
    CardInfoText,
    CardOrangeText,
    CardTopAnchor,
    CardTopIcon
} from "../../../../CampaignHome/style";


interface CardProps {
  cardNumber: number
  header: ReactNode
  detail: ReactNode
}

const StepCard = (props: CardProps) => (
    <CardSpacer>
        <ServiceCard>
            <CardTopAnchor>
                <CardTopIcon>{props.cardNumber}</CardTopIcon>
            </CardTopAnchor>
            <CardOrangeText>{props.header}</CardOrangeText>
            <CardInfoText dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.detail, { ADD_ATTR: ["target"] }) }} />
        </ServiceCard>
    </CardSpacer>
)

export default (props) => {
    const ObtainHCPServices = () => {
        return (
            <CardsSection>
                <StepCard
                    cardNumber={1}
                    header="Register for an Assessment"
                    detail='Call My Aged Care on 1800 200 422 or <a href="https://www.myagedcare.gov.au/assessment/apply-online" target="_blank">apply online here.</a>'
                />
                <StepCard
                    cardNumber={2}
                    header="Your Assessment"
                    detail="A face-to-face assessment will be scheduled to discuss your needs."
                />
                <StepCard
                    cardNumber={3}
                    header="Your HCP is assigned"
                    detail="You will receive a letter in the post indicating which HCP level you have been approved for. <b>NB: Waiting times are 6-12months</b>"
                />
                <StepCard
                    cardNumber={4}
                    header="Contact Karista and choose a provider to start receiving services"
                    detail="We can help you find a Home Care provider that is right for you. We’ll also complete the paperwork so you can start receiving subsidised services hassle free!"
                />
            </CardsSection>
        );
    }

    return(
    <Theme theme={themeLightGrey}>
        <HeaderSection>
            <HeaderHeading><b>How do I obtain a Home Care Package and start receiving services?</b></HeaderHeading>
        </HeaderSection>
        <ObtainHCPServices />
        <HeaderSection>
            <BlackInfoText>You may also need to arrange an income assessment from Services Australia by calling <b>1800 227 475</b></BlackInfoText>
        </HeaderSection>
        <br />
    </Theme>
)};