import React from 'react';

import Footer from '../../components/Footer';
import Navigation from '../../components/Navigation';
import Page from '../../components/Page';
import Section from '../../components/Section';
import Theme from '../../components/Theme';
import { themeSecondary } from '../../styles/constants';
import { ButtonLink } from '../../components/Link';
import { Grid } from '../../components/Grid';
import { Paragraph } from './style';
import { BetaHeading } from '../../components/Typography';
import {
  FlexedGridItem,
  ImageShowOnMobile,
  ImageShowOnMobileHCP
} from './PageNotFound/style';

function FundingData() {
  const openChat = e => {
    e.preventDefault();
    const chatButton = document.getElementById('esw-fab');
    if (chatButton) {
      chatButton.click();
    }
  };

  return (
    <Section style={{ textAlign: 'center' }}>
      <BetaHeading>Learn about Funding Types</BetaHeading>
      <Grid wrap="wrap" alignItems="flex-end">
        <FlexedGridItem mobile={12} tablet={6}>
          <Paragraph>National Disability Insurance Scheme (NDIS)</Paragraph>
          <ImageShowOnMobile
            src="https://res.cloudinary.com/karista/image/upload/v1720135085/image-key-worker_2_njmwq3.png"
            alt="ndis - national disability scheme a girl with purple hair and a boy next to a couch with cushion"
          />
          <ButtonLink
            style={{ marginTop: '40px' }}
            to={{
              pathname: `/funding-information/national-disability-insurance-scheme`
            }}
          >
            Learn more about NDIS
          </ButtonLink>
        </FlexedGridItem>
        <FlexedGridItem mobile={12} tablet={6}>
          <Paragraph>Home Care Package Funding (HCP) </Paragraph>
          <ImageShowOnMobileHCP
            src="https://res.cloudinary.com/karista/image/upload/t_Square-Image/v1720134933/Group_7_ozzame.png"
            alt="Home care package old man and a woman on wheelchair"
          />
          <ButtonLink
            style={{ marginTop: '40px' }}
            to={{ pathname: `/funding-information/home-care-package-funding` }}
          >
            Learn more about HCP
          </ButtonLink>
        </FlexedGridItem>
      </Grid>
      <FlexedGridItem>
        <Paragraph>Is there something else we can help you with? </Paragraph>
        <ButtonLink to="/" onClick={openChat}>
          Get in touch
        </ButtonLink>
      </FlexedGridItem>
    </Section>
  );
}

function FundingInformation() {
  return (
    <Theme theme={themeSecondary}>
      <Navigation />
      <Page>
        <FundingData />
      </Page>
      <Footer />
    </Theme>
  );
}

export default FundingInformation;
