import React from 'react';
import DOMPurify from 'dompurify';
import { Helmet } from 'react-helmet';

import Button from '../../components/Button';
import Footer from '../../components/Footer';
import Navigation from '../../components/Navigation';
import { OrangeLink } from '../../components/Link';
import Page from '../../components/Page';
import Section from '../../components/Section';
import Theme from '../../components/Theme';
import { themeSecondary } from '../../styles/constants';
import {
  StyledLink,
  ButtonSection,
  ButtonBetaText,
  ButtonPlacementSection,
  HeaderHeading,
  NumberList,
  TermsListItem
} from './style';

const PrivacyPolicy = () => {
  const paragraphs = [
    `<p><b>Policy Introduction</b></p><br>
        <p>Karista Pty Ltd (ABN 92 614 763 076) (“Karista”, "we", "us" or “our”) collects and uses personal and
        sensitive information in accordance with applicable privacy laws, including the Privacy Act 1988 (Cth)
        and the Australian Privacy Principles set out in the Privacy Act.</p><br>
        <p>This Privacy Policy outlines how Karista manages personal information and sensitive information
        provided by you, or otherwise obtained by Karista, relating to you.</p><br>`,
    `<p><b>What is Personal information and Sensitive information</b></p><br>
        <p><b>“Personal information”</b> is information about an identified individual, or individual who is reasonably
        identifiable, whether the information or opinion is true or not and whether the information or opinion
        is recorded in a material form or not. Examples include an individual’s name, address, contact number
        or email address.</p>
        <p><b>“Sensitive information”</b> is a subset of personal information that is given a higher level of protection
        under the Australian Privacy Principles. It means information or opinion relating to your racial or ethnic
        origin, political opinions, religion, trade union or other professional associations or memberships,
        philosophical beliefs, sexual orientation or practices, criminal records, Health Information, or biometric
        information.</p>
        <p><b>“Health information”</b> is any personal information about your health or disability. It includes
        information or opinion about your illness, injury, or disability. Examples include notes of your symptoms
        or diagnosis and information about a health service you’ve had or will receive.</p><br>`,
    `<p><b>Collection of Personal information and Sensitive information</b></p><br>
        <p>Karista may collect and hold personal information or sensitive information of the following kinds
        (without limitation):</p>
        <ul style="padding-left: 0;">
            <li style="margin-left: 1.5em;">name, address, phone number, post code, date of birth, email address;</li>
            <li style="margin-left: 1.5em;"}>your credit card or bank details;</li>
            <li style="margin-left: 1.5em;">information about goods or services ordered, acquired or supplied;</li>
            <li style="margin-left: 1.5em;">information you provide us from enquiries made to provide our services to you. Examples include gender, age, diagnosis, goals, services requested, preferences such as gender, language and cultural preferences to allow us to refer (or suggest) suitable service providers to you;</li>
            <li style="margin-left: 1.5em;">preferred start date for services and expected duration of service provision;</li>
            <li style="margin-left: 1.5em;">information you provide us through customer surveys, reviews or to run competitions and/or
            offer additional benefits to you;</li>
            <li style="margin-left: 1.5em;">communications between Karista and you (or if you are not the care recipient, the care
            recipient);</li>
            <li style="margin-left: 1.5em;">whether you (or if you are not the care recipient, the care recipient) have or have you applied
            for a government funding package, such as the National Disability Insurance Scheme or Home
            Care Package;</li>
            <li style="margin-left: 1.5em;">if you are contacting us on behalf of the care recipient, we will also collect some personal
            information about you, including your name, contact details and your relationship to the
            person being referred;</li>
            <li style="margin-left: 1.5em;">your browser session and geo-location data, device and network information, statistics on
            page views and sessions, search queries and/or browsing behaviours;</li>
            <li style="margin-left: 1.5em;">additional personal information or sensitive information that you provide to us, directly or
            indirectly, using our website, associated social media platforms and/or accounts from which
            you permit us to collect information; and</li>
            <li style="margin-left: 1.5em;">data from third parties as Google Analytics, Google Salesforce or other relevant businesses.
            This may include parties that store data outside Australia.</li>
        </ul><br>
        <p>Karista will take reasonable steps to ensure that the personal information or sensitive information that
        is collected, used, or disclosed by it is complete and up to date. Karista will only collect personal
        information or sensitive information about an individual from that individual unless it is unreasonable
        or impractical to do so.</p><br>
        <p>If you disclose the care recipient’s personal information or sensitive information to us, in providing that
        personal information or sensitive information to us you agree that:</p>
        <ul style="padding-left: 0;">
            <li style="margin-left: 1.5em;">you have drawn this Privacy Policy to the care recipient’s attention;</li>
            <li style="margin-left: 1.5em;">the care recipient has agreed to you providing their personal information or sensitive
            information to us; and</li>
            <li style="margin-left: 1.5em;">that care recipient understands and agrees that we may use, disclose and manage their
            personal information or sensitive information as described in this Privacy Policy.</li>
        </ul><br>
        <p>If you have agreed to receive information from Karista, your email address will be saved and used for
        personal email advertisements until you let us know that you wish to unsubscribe.</p><br>
        <p>When you visit a page from the Karista website, we may collect your IP address, the date and time of
        your visit, the retrieved data and the version of your browser, as well as any information that is
        submitted by default. We use the information we collect through cookies to deliver the basic
        functionality of our website, as well as to monitor and improve website performance. In addition,
        cookies may be used to serve relevant ads to website visitors through third party services such as
        Google Adwords. These ads may appear on the Karista website or other websites you visit.</p><br>
        <p>Cookies are very small files which a website uses to identify you when you come back to the site and to
        store details about your use of the website. Cookies are not malicious programs that access or damage
        your computer. Most web browsers automatically accept cookies but you can choose to reject cookies
        by changing your browser settings. However, this may prevent you from taking full advantage of our
        website.</p><br>`,
    `<p><b>Use and Disclosure of Personal information and Sensitive information</b></p><br>
        <p>Karista may collect, hold, use and disclose personal information or sensitive information for the
        purposes of responding to enquiries from you and providing services to you or the care recipient whom
        you represent, to maintain Karista’s relationship with you or the care recipient whom you represent, to
        comply with legal, regulatory, professional or contractual requirements, to interact with relevant third
        parties (including service providers), or where you have provided your consent for Karista to do so.
        Karista will only use or disclose your personal information or sensitive information for: the purpose for
        which it was collected; any related purpose for which it would reasonably be expected to be used or
        disclosed; a purpose required or permitted by law; or a purpose for which the individual has provided
        consent.</p><br>
        <p>Examples of instances where Karista may disclose personal information or sensitive information about
        individuals to third parties include disclosure to clients and prospective clients known by Karista,
        providers of services to Karista, government agencies, regulatory authorities, related bodies corporate
        of Karista and professional advisers of Karista. Karista requires its service providers to keep the personal
        information or sensitive information confidential and not use it for any purpose other than performing
        those services.</p><br>
        <p>Karista is unlikely to disclose personal information or sensitive information to overseas recipients.
        Where we disclose your personal information or sensitive information to third parties, these third
        parties may also store, transfer or access personal information outside of Australia. Unless we seek and
        receive your consent to an overseas disclosure of your personal information or sensitive information in
        a way which is substantially similar the Australian Privacy Principles , we will take steps as are
        reasonable in the circumstances to require that overseas recipients protect your personal information
        or sensitive information in accordance with the Australian Privacy Principles.</p><br>`,
    `<p><b>Security of Personal information and Sensitive information</b></p><br>
        <p>Karista takes reasonable measures to secure and protect personal information or sensitive information
        from misuse, interference, loss, unauthorised access, modification, or disclosure. However, Karista
        cannot guarantee the security of any information that is transmitted to or by us over the Internet.</p>
        <p>Where the personal information or sensitive information is no longer required, subject to the relevant
        laws and regulations, we will take reasonable steps to ensure that the personal information or sensitive
        information is de-identified or destroyed.</p><br>`,
    `<p><b>Access to, and Correcting Personal Information and Sensitive Information</b></p><br>
        <p>You may access or seek correction of your personal information held by Karista by written request.
        Karista will respond to your request in accordance with its legal obligations under applicable privacy
        laws.</p><br>`,
    `<p><b>Updates to this Privacy Policy</b></p><br>
        <p>This Privacy Policy may be updated by Karista from time to time.</p><br>`,
    `<p><b>Intranet and Website</b></p><br>
        <p>This Privacy Policy as amended from time to time is to be placed on the Karista intranet and website
        and is also available upon request.</p><br>`,
    `<p><b>Concerns, Queries and Complaints</b></p><br>
        <p>Karista welcomes any questions or feedback concerning this Privacy Policy. Any questions or feedback
        will be reviewed by Karista.</p>`
  ];

  const handleClick = () => {
    // Scroll to the top of the page
    window.scrollTo(0, 0);
  };

  const SupportButton = () => (
    <ButtonPlacementSection>
      <ButtonSection>
        <Button>
          <StyledLink onClick={handleClick} to="/">
            <ButtonBetaText>Search for supports now</ButtonBetaText>
          </StyledLink>
        </Button>
      </ButtonSection>
    </ButtonPlacementSection>
  );

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <Theme theme={themeSecondary}>
        <Navigation>
          <OrangeLink border to="/">
            Back to search
          </OrangeLink>
        </Navigation>
        <Page>
          <Section>
            <ButtonPlacementSection>
              <HeaderHeading>Karista Privacy Policy</HeaderHeading>
            </ButtonPlacementSection>
            <NumberList>
              {paragraphs.map(paragraph => (
                <TermsListItem
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(paragraph)
                  }}
                />
              ))}
            </NumberList>
            <SupportButton />
          </Section>
        </Page>
        <Footer />
      </Theme>
    </>
  );
};
export default PrivacyPolicy;
