import React from 'react';
import DOMPurify from 'dompurify';
import { bindActionCreators } from 'redux';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import {ThemeProvider} from 'styled-components';

import * as adminActions from '../Admin/actions';
import * as providerActions from '../Providers/actions';
import Hero from './components/Hero';
import Footer from '../../components/Footer';
import Navigation from '../../components/Navigation';
import Search from './components/Search';
import Theme from '../../components/Theme';
import { LandingPixel } from '../../components/Trackers';
import { themeSecondary, themeLightGrey} from '../../styles/constants';
import { setNewCampaign } from '../../util/local-storage';
import { AdminActions } from '../Admin/actions';
import { ProvidersActions } from '../Providers/actions';
import WhatWeDoCard from "../../components/WhatWeDoCard";
import ServiceInfo from "./components/ServiceInfo";
import CallUs from './components/CallUs';
import karistaApi from '../../services/karista';
import {skipToken} from "@reduxjs/toolkit/query/react";
import { reduceQueries } from '../../util/rtk-query';
import { find } from 'lodash';
import Helmet from 'react-helmet';

import {
  Banner,
  HeaderText,
  SearchSection,
  BlackInfoText,
  BottomSearchSection,
  CampaignHeading,
  ConfidentialityIcon,
  ConfidentialitySection,
  HeaderHeading,
  HeaderSection,
  CallLink
} from "./style";

interface HomeProps extends RouteComponentProps {
  actions: ProvidersActions,
  adminActions: AdminActions,
  campaign: string
};

const useInitialize = () => reduceQueries({
  serviceAreas: karistaApi.useGetAllServiceAreasQuery(),
  ageGroups: karistaApi.useGetAllAgeGroupsQuery(),
  fundingTypes: karistaApi.useGetAllFundingTypesQuery(),
});

const CampaignHome = (props: HomeProps) => {
  const { campaign } = props;

  const defaultContent = 'ideal home care or disability service';
  React.useEffect(() => setNewCampaign(campaign), []);
  const {data, isFetching} = karistaApi.useGetCampaignQuery(campaign || skipToken);
  const { serviceAreas, ageGroups, fundingTypes,  isLoading  } = useInitialize();

  if (isFetching || !serviceAreas || !ageGroups || !fundingTypes || isLoading || !campaign) {
    return (
      <div>Loading ...</div>
    );
  }
  if (!data) {
    setNewCampaign('');
    window.location.href = "/";
    return null;
  }

  const serviceAreaList = serviceAreas.data || [];
  const ageGroupsData = ageGroups.data || [];
  const fundingTypesData = fundingTypes.data || [];
  const selectedServiceArea = find(
    serviceAreaList,
    serviceArea =>
      serviceArea.id === Number(data.selected_service_area)
  )
  const selectedAgeGroup = data.selected_age_group && ageGroupsData ? ageGroupsData[data.selected_age_group] : "" ;
  const selectedFundingType = data.selected_funding_type && fundingTypesData ? fundingTypesData[data.selected_funding_type] : "";


  return (
    <Theme theme={themeSecondary}>
      <Helmet>
        <meta name="description" content={data ? data.meta_description : defaultContent} />
      </Helmet>
      <LandingPixel />
      <Navigation isHome />
      <Banner>
          <b>Call us on  <CallLink href="tel:+61485972676">0485 972 676</CallLink> Monday to Friday 9am to 5pm AEST</b>
      </Banner>
        <Hero content={data ? data.heading: defaultContent}
              heading_image={data.heading_image_url}
              mobile_heading_image={data.mobile_heading_image_url}
              slug={campaign}/>
        <HeaderSection>
        <HeaderText dangerouslySetInnerHTML={{ __html: data ? DOMPurify.sanitize(data.blurb) : "" }}
      />
      </HeaderSection>
      <Theme theme={themeLightGrey}>
        <SearchSection>
          <Search {...props} serviceAreaPrefill={selectedServiceArea && selectedServiceArea.name}
                             ageGroupPrefill={selectedAgeGroup && selectedAgeGroup.code}
                             fundingTypePrefill={selectedFundingType && selectedFundingType.code}
                             showHeading={true}
          />
        </SearchSection>
      </Theme>
      <ServiceInfo info_sections={data.info_sections} />
      <Theme theme={themeLightGrey}>
        <HeaderSection>
            <HeaderHeading>How does Karista work?</HeaderHeading>
        </HeaderSection>
        <WhatWeDoCard />
      </Theme>
      <Theme theme={themeSecondary}>
          <ConfidentialitySection>
              <ConfidentialityIcon alt="Lock icon" />
              <BlackInfoText>
                  <b>Confidentiality</b>: Your details are not shared until you give us permission,
                  so you know that your private information is kept confidential.
              </BlackInfoText>
          </ConfidentialitySection>
      </Theme>
      <Theme theme={themeLightGrey}>
        <BottomSearchSection>
          <CampaignHeading>
            {data.search_heading}
          </CampaignHeading>
          <Search {...props} serviceAreaPrefill={selectedServiceArea && selectedServiceArea.name}
                             ageGroupPrefill={selectedAgeGroup && selectedAgeGroup.code}
                             fundingTypePrefill={selectedFundingType && selectedFundingType.code}
                             showHeading={false}
          />        
        </BottomSearchSection>                                                                                                                                                    
      </Theme>
      <ThemeProvider theme={themeSecondary}>
        <CallUs></CallUs>
      </ThemeProvider>
      <Footer />
    </Theme>
  );
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(providerActions, dispatch),
  adminActions: bindActionCreators(adminActions, dispatch)
});

export default connect(() => ({}), mapDispatchToProps)(CampaignHome);
