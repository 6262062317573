import React from 'react';
import Theme from '../../../../components/Theme';
import { themeSecondary } from '../../../../styles/constants';
import IconRocket from "./icon-rocket.svg"
import IconProfile from "./icon-profile.svg"
import Breakpoint from '../../../../components/Breakpoint';
import {
    AboutUsParagraph,
    BioPhoto,
    HeaderSection,
    HeaderText,
    HeaderHeading,
    RocketIcon,
    MissionStatement
} from "../../style"


// Shared component for both desktop and mobile versions
const LeadershipContent = () => (
  <>
    <HeaderHeading>CEO and founder
        <br></br>
        Danielle Bodinnar
    </HeaderHeading>
    <HeaderText>
        <AboutUsParagraph>
            Karista was founded in 2016 by CEO, Danielle Bodinnar, a passionate entrepreneur,
            after she was inspired by the changes emerging in the healthcare industry.
            <br></br>
            <br></br>
            Danielle has a deep knowledge of the aged and disability sectors of the 
            Healthcare industry from her personal experience and professionally, 
            leading large corporate businesses in Healthcare.
        </AboutUsParagraph>
    </HeaderText>
  </>
);

// Shared component for both desktop and mobile versions
const MissionStatementContent = () => (
  <>
    <HeaderText>
        <MissionStatement>Our Mission and Vision </MissionStatement>
        <AboutUsParagraph>
            Danielle and the Karista team are on a mission to impact 1 million lives by 2025
            and share a vision of providing better care at a lower total cost to consumers and providers.
        </AboutUsParagraph>
    </HeaderText>
  </>
);


const LeadershipIntro = () => {
    const renderLeadershipDesktop  = () => {
      return (
        <HeaderSection>
            <BioPhoto>
              <img src={IconProfile} alt={''} />
            </BioPhoto>
            <div>
                <LeadershipContent />
                <RocketIcon><img src={IconRocket} alt={'Our Mission'} /></RocketIcon>
                <MissionStatementContent />
            </div>
        </HeaderSection>
      );
    }

    const renderLeadershipMobile  = () => {
      return (
        <HeaderSection>
            <div>
                <LeadershipContent />
                <BioPhoto>
                  <img src={IconProfile} alt={''} />
                </BioPhoto>
                <MissionStatementContent />
            </div>
        </HeaderSection>
      );
    }

    return (
      <Theme theme={themeSecondary}>
          <Breakpoint>
            {breakpoint => {
              if (breakpoint === 'desktop' || breakpoint === 'tablet') return renderLeadershipDesktop();
              return renderLeadershipMobile();
            }}
          </Breakpoint>
      </Theme>
    );
}

export default LeadershipIntro;
