import React from 'react';

import Footer from '../../components/Footer';
import Navigation from '../../components/Navigation';
import { OrangeLink } from '../../components/Link';
import Theme from '../../components/Theme';
import { themeLightGrey } from '../../styles/constants';
import HCPIntroduction from './components/HCP/HCPIntroduction';

const HCPFundingInformation = () => (
  <Theme theme={themeLightGrey}>
    <Navigation>
      <OrangeLink border to="/">
        Back to search
      </OrangeLink>
    </Navigation>

    <HCPIntroduction />
    <Footer />
  </Theme>
);

export default HCPFundingInformation;
