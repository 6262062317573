import React from 'react';
import { bindActionCreators } from 'redux';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import ReactPlayer from 'react-player';
import styled, {ThemeProvider} from 'styled-components';

import mediaQueries from '../../styles/mediaQueries';
import * as adminActions from '../Admin/actions';
import * as providerActions from '../Providers/actions';
import Button from '../../components/Button';
import Campaign from './components/Campaign';
import Hero from './components/Hero';
import Breakpoint from '../../components/Breakpoint';
import Footer from '../../components/Footer';
import { Grid, GridItem } from '../../components/Grid';
import Navigation from '../../components/Navigation';
import Page from '../../components/Page';
import Search from './components/Search';
import Theme from '../../components/Theme';
import { VideoPreview } from '../../components/VideoPlayer';
import { LandingPixel } from '../../components/Trackers';
import { getNewCampaign } from '../../util/local-storage';
import {themePrimary, colors, themeSecondary} from '../../styles/constants';
import Section from '../../components/Section';

import { DISPLAY_INTRO_VIDEO } from './constants';

import { AdminActions } from '../Admin/actions';
import { ProvidersActions } from '../Providers/actions';
import WhatWeDo from "./components/WhatWeDo";
import { GammaHeading } from '../../components/Typography';
import CampaignHome from "../CampaignHome";

const StyledSection = styled(Section)`
  text-align: center;
  ${mediaQueries.mobile`
    padding-top: 24px;
  `};
`;

const StyledPlayerWrapper = styled.div`
  position: relative;
  max-width: 720px;
  margin: 0 auto;
`;

const StyledReactPlayer = styled(ReactPlayer)`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const StyledButton = styled(Button)`
  position: absolute;
  z-index: 9999;
  top: 200px;
  right: 40px;
  padding: 0 16px;
  min-height: 30px;
  ${mediaQueries.mobile`
    display: none;
  `};
`;

const CallUsSection = styled.section`
  margin: 0;
  text-align: center;
  padding-top: 48px;
  padding-bottom: 56px;
  ${props => ({...props.theme})};
`;

const CallUsPhoneNumber = styled(GammaHeading).attrs({
  as: 'a',
  href: 'tel:+61485972676',
})`
  color: ${colors.orange};
  margin-bottom: 8px;
  text-decoration: none;
`;

const CallUsHeading = styled(GammaHeading)`
  line-height: 28px;
  margin: 0;
`;

const StyledPage = styled(Page)`
  padding-bottom: 0;
`;

interface HomeProps extends RouteComponentProps {
  actions: ProvidersActions,
  adminActions: AdminActions,
};

const Banner = styled.div`
  color: ${colors.darkPurple};
  background: ${colors.orange};
  text-align: center;
  padding: 10px 10px;
`;

export const CallLink = styled.a`
  color: ${colors.darkPurple};
  text-decoration: none;
`;

const Home = (props: HomeProps) => {
  const [playing, setPlaying] = React.useState<boolean>(false);
  const videoUrl = 'https://player.vimeo.com/video/268888584';
  const newCampaign = String(getNewCampaign())

  const HeroDesktop = () => {
    const modalStyle: React.CSSProperties = playing
      ? {
          position: 'absolute',
          width: '100%',
          height: '100%',
          background: 'rgba(62, 15, 72, 0.9)',
          zIndex: '999',
          top: '0px',
          left: '0px'
        }
      : { display: 'relative' };
    const modalDesktopStyle = playing
      ? {
          position: 'relative',
          top: '100px',
          background: 'transparent'
        }
      : { display: 'none' };
    const wrapperStyle = playing
      ? {
          top: '10%'
        }
      : {};

      return (
      <div>
        <StyledSection>
          <Grid wrap="wrap" alignItems="flex-end">
            <GridItem mobile={12} tablet={6}>
              <Hero />
            </GridItem>
            <GridItem mobile={12} tablet={6}>
              <div style={modalStyle}>
                <StyledPlayerWrapper
                  style={wrapperStyle}
                  onClick={() => {
                    setPlaying(!playing);
                  }}
                >
                  <StyledButton
                    style={!playing ? { display: 'none' } : {}}
                    onClick={() => {
                      setPlaying(!playing);
                    }}
                  >
                    X
                  </StyledButton>
                  <StyledReactPlayer
                    width="100%"
                    style={modalDesktopStyle}
                    url={videoUrl}
                    playing={playing}
                    config={{
                      vimeo: {
                        playerOptions: { playsinline: false },
                        preload: true
                      }
                    }}
                    onPlay={() => setPlaying(true)}
                    onPause={() => setPlaying(false)}
                    playsinline
                    onError={() => console.error('Vimeo Player Error')} // eslint-disable-line
                  />
                  {!playing ? <VideoPreview /> : null}
                </StyledPlayerWrapper>
              </div>
            </GridItem>
          </Grid>
        </StyledSection>
      </div>
    );
  }

  const HeroMobile = () => {
    return (
      <div>
        <StyledSection>
          <Grid wrap="wrap" alignItems="flex-end">
            <GridItem mobile={12} tablet={6}>
              <Campaign render={campaign => <Hero />} />
            </GridItem>
            <GridItem mobile={12} tablet={6}>
              <StyledPlayerWrapper>
                <StyledReactPlayer
                  width="100%"
                  height="200px"
                  style={{
                    background: 'transparent'
                  }}
                  url={videoUrl}
                  playing={playing}
                  onPlay={() => setPlaying(true)}
                  onPause={() => setPlaying(false)}
                />
              </StyledPlayerWrapper>
            </GridItem>
          </Grid>
        </StyledSection>
      </div>
    );
  }

  return (
    newCampaign ? (
      <CampaignHome {...props} campaign={newCampaign}/>
    ) : (
      <Theme theme={themePrimary}>
        <LandingPixel />
        <Navigation isHome />
        <Banner>
          <b>Call us on <CallLink href="tel:+61485972676">0485 972 676</CallLink> Monday to Friday 9am to 5pm AEST</b>
        </Banner>
        <StyledPage>
          {DISPLAY_INTRO_VIDEO ? (
            <Breakpoint>
            {breakpoint => (
              breakpoint === 'desktop' ? <HeroDesktop /> : <HeroMobile />
            )}
          </Breakpoint>
          ) : (
            <StyledSection>
              <Campaign render={campaign => <Hero />} />
            </StyledSection>
          )}
          <Search {...props} />
          <WhatWeDo />
          <ThemeProvider theme={themeSecondary}>
            <CallUsSection>
              <CallUsPhoneNumber><b>0485 972 676</b></CallUsPhoneNumber>
              <CallUsHeading>
                Call us Monday to Friday <br />
                9am to 5pm AEST
              </CallUsHeading>
            </CallUsSection>
          </ThemeProvider>
        </StyledPage>
        <Footer />
      </Theme>
    )
  );  
};
const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(providerActions, dispatch),
  adminActions: bindActionCreators(adminActions, dispatch)
});

export default connect(() => ({}), mapDispatchToProps)(Home);
