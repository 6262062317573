import React from 'react';

import Footer from '../../components/Footer';
import Navigation from '../../components/Navigation';
import { OrangeLink } from '../../components/Link';
import Theme from '../../components/Theme';
import { themeSecondary } from '../../styles/constants';
import LeadshipIntro from './components/LeadershipIntro';
import NotJustADirectory from './components/NotJustADirectory';
import HowCanWeHelp from './components/HowCanWeHelp';

import { CallUsSection, CallUsPhoneNumber, CallUsHeading } from './style';

const About = () => (
  <Theme theme={themeSecondary}>
    <Navigation>
      <OrangeLink border to="/">
        Back to search
      </OrangeLink>
    </Navigation>
    <LeadshipIntro />
    <NotJustADirectory />
    <HowCanWeHelp />
    <CallUsSection>
      <CallUsPhoneNumber>0485 972 676</CallUsPhoneNumber>
      <CallUsHeading>
        Call us Monday to Friday <br />
        9am to 5pm AEST
      </CallUsHeading>
    </CallUsSection>
    <Footer />
  </Theme>
);

export default About;
