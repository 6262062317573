import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import Home from './apps/Home';
import About from './apps/Static/About';
import LiveChat from './apps/Static/LiveChat';
import RequestSuccess from './apps/Static/request_success';
import HCPFundingInformation from './apps/Static/HCPFunding';
import NDISFundingInformation from './apps/Static/NDISFunding';
import FundingInformation from './apps/Static/FundingInformation';
import TermsAndConditions from './apps/Static/TermsAndConditions';
import PrivacyPolicy from './apps/Static/PrivacyPolicy';
import ModerationPolicy from './apps/Static/ModerationPolicy';
import ProviderInfo from './apps/Static/ProviderInfo';
import EnquireForm from './apps/Static/EnquireForm';
import C2CForm, { C2CFormSuccess } from './apps/Static/C2CForm';
import FormRedirect from './apps/Static/FormRedirect';
import Providers from './apps/Providers';
import Admin from './apps/Admin';
import HistoryListener from './util/HistoryListener';
import PageNotFound from './apps/Static/PageNotFound';
import GlobalStyles from './styles/global';
import CampaignHome from './apps/CampaignHome';
import { store, persistor } from './state/store';
import { history } from './history';

const App = () => (
  <Provider store={store}>
    <GlobalStyles />
    <PersistGate loading={null} persistor={persistor}>
      <Router history={history}>
        <div>
          <HistoryListener />
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/providers" component={Providers} />
            <Route path="/admin" component={Admin} />
            <Route path="/about" component={About} />
            <Route
              path="/funding-information/national-disability-insurance-scheme"
              component={NDISFundingInformation}
            />
            <Route
              path="/funding-information/home-care-package-funding"
              component={HCPFundingInformation}
            />
            <Route path="/funding-information" component={FundingInformation} />
            <Route path="/live-chat" component={LiveChat} />
            <Route
              path="/live-chat-request-success"
              component={RequestSuccess}
            />
            <Route
              path="/terms-and-conditions"
              component={TermsAndConditions}
            />
            <Route path="/privacy-policy" component={PrivacyPolicy} />
            <Route path="/moderation-policy" component={ModerationPolicy} />
            <Route
              path="/list-your-organisation/success"
              component={FormRedirect}
            />
            <Route
              exact
              path="/list-your-organisation"
              component={ProviderInfo}
            />
            <Route
              path="/list-your-organisation/enquire-now"
              component={EnquireForm}
            />
            <Route path="/c2c/success" component={C2CFormSuccess} />
            <Route exact path="/c2c" component={C2CForm} />
            <Route
              path="/services/:campaignSlug"
              render={props => {
                const { campaignSlug } = props.match.params;
                return <CampaignHome {...props} campaign={campaignSlug} />;
              }}
            />
            <Route path="/services" component={Home} />
            <Route path="*" component={PageNotFound} />
          </Switch>
        </div>
      </Router>
    </PersistGate>
  </Provider>
);

export default App;
