import React from 'react';

import Footer from '../../components/Footer';
import Navigation from '../../components/Navigation';
import { OrangeLink } from '../../components/Link';
import Theme from '../../components/Theme';
import { themeLightGrey } from '../../styles/constants';
import ProviderIntroduction from './components/ProviderIntroduction';
import HowWeWork from './components/HowWeWork';
import ProviderBenefits from './components/ProviderBenefits';

import {
  StyledLink,
  ProviderButtonSection,
  CustomStyledButton,
  OrangeStyledButton
} from '../Static/style';

const ProviderInfo = () => (
  <Theme theme={themeLightGrey}>
    <Navigation>
      <OrangeLink border to="/">
        Back to search
      </OrangeLink>
    </Navigation>
    <ProviderButtonSection>
      <OrangeStyledButton>
        <StyledLink to="/list-your-organisation/enquire-now">
          <b>Enquire now</b>
        </StyledLink>
      </OrangeStyledButton>
      <CustomStyledButton>
        <StyledLink to="/admin/login">
          <b>Provider Login</b>
        </StyledLink>
      </CustomStyledButton>
    </ProviderButtonSection>
    <ProviderIntroduction />
    <ProviderBenefits />
    <HowWeWork />
    <Footer />
  </Theme>
);

export default ProviderInfo;
