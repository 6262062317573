import React from 'react';

import Footer from '../../components/Footer';
import Navigation from '../../components/Navigation';
import { OrangeLink } from '../../components/Link';
import Theme from '../../components/Theme';
import { themeLightGrey } from '../../styles/constants';
import NDISIntroduction from './components/NDIS/NDISIntroduction';

const NDISFundingInformation = () => (
  <Theme theme={themeLightGrey}>
    <Navigation>
      <OrangeLink border to="/">
        Back to search
      </OrangeLink>
    </Navigation>

    <NDISIntroduction />
    <Footer />
  </Theme>
);

export default NDISFundingInformation;
