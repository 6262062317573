import React from 'react';
import DOMPurify from 'dompurify';
import { Helmet } from 'react-helmet';

import Button from '../../components/Button';
import Footer from '../../components/Footer';
import Navigation from '../../components/Navigation';
import { OrangeLink } from '../../components/Link';
import Page from '../../components/Page';
import Section from '../../components/Section';
import Theme from '../../components/Theme';
import { themeSecondary } from '../../styles/constants';
import {
  StyledLink,
  ButtonSection,
  ButtonBetaText,
  ButtonPlacementSection,
  HeaderHeading,
  NumberList,
  TermsListItem
} from './style';

const TermsAndConditions = () => {
  const paragraphs = [
    `The following provisions govern your use of Karista.com.au (the <b>"Karista® Website"</b>) and all other services made available to you through the Karista Website. By accessing	and	using 
        the Karista Website, you confirm that you have read, understood and agree to be	bound by these Terms and Conditions	and	the	Privacy	Policy which constitute	a contract between you
        and	Karista	Pty	Ltd	(ABN 92	614	763	076) (<b>“Karista”, "we", "us"</b> or <b>“our”</b>).	`,
    `On the Karista Website, you have the ability to compare third party services provided by third parties (<b>“Service Providers”</b>). The Karista Website also provides suggestions on 
        Service Providers’ products and services based on the data you have inputted on the Karista Website. Any suggestions provided by the Karista Website are generated by 
        automated software and are for your information only.`,
    `In addition, you have the ability to request a quote from Service Providers (<b>Request a Quote Service</b>), which the Karista Website will forward to the relevant Service Provider. 
        Any subsequent correspondence or booking arising from the Request a Quote Service will be between the Service Provider and you. Therefore, any resulting agreement 
        (and therefore legal relationship) will be created solely between the Service Provider and you, and therefore no contract relating to a Service Provider is concluded between 
        you and Karista.`,
    `The Karista Website also provides you with the opportunity to rate and review Service Providers in order to assist other users of the Karista Website to assess the suitability 
        of products and services provided by Service Providers. The ratings and reviews will be listed on the respective Service Providers’ profiles.`,
    `We work with a number of Service Providers whose websites are linked to the Karista Website and which are controlled by parties other than us (each a <b>“Third Party Website”</b>). 
        These Third Party Websites will have their own terms and conditions of use and you should familiarise yourself with these. All Third Party Websites are linked "as is" and we 
        are not responsible for and do not endorse or accept any responsibility for the availability, contents, products, services or use of any Third Party Website, any website accessed 
        from a Third Party Website or any changes or updates to such sites. We do not make any warranties or representations regarding the quality, accuracy, merchantability or fitness 
        for purpose of any material on any Third Party Websites linked from or to the Karista Website.`,
    `The Service Providers are solely responsible for their information appearing on the Karista Website. Karista does not verify the accuracy and content uploaded by the Service Providers on our Website.`,
    `We also make no representation or warranty that your access to and use of the Karista Website will be uninterrupted, secure, error-free, free of viruses or unauthorised 
        code or other harmful components. We reserve the right to discontinue operating the Karista Website at any time without notice.`,
    `You are granted a non-exclusive, non-assignable and non-transferable licence to use the Karista Website only in accordance with these Terms and Conditions. 
        Nothing in these Terms and Conditions or the Karista Website will give you ownership of the content. You may not sell, modify, copy, distribute, transmit, display, 
        perform, reproduce, republish, licence, frame, upload, transmit, post, communicate or use the content except as expressly authorised either by Karista (in writing) or 
        these Terms and Conditions or permitted under law.`,
    `Karista owns or is entitled under licence to all rights, title and interests (including present and future copyright) in the Karista Website. All material including domain names, 
        company and business names, text, graphics, images, photographs, designs, illustrations, diagrams, logos, buttons, icons, software, and all products and services described on 
        the Karista Website are owned exclusively by Karista or others who have licensed their material to Karista.`,
    `Karista reserves the right to all other intellectual property that is inherent in other information, products, processes or technologies which form part of the Karista Website and are not explicitly licensed.`,
    `While care and diligence have been used to maintain the information on the Karista Website, it may not be accurate, current or complete in all respects and, consequently, 
        Karista does not make any representations or warranties as to the accuracy, currency or completeness of the information.`,
    `Karista is not responsible to you or anyone else for any loss suffered in connection with the use of the Karista Website or any of its content. Karista excludes, to the 
        maximum extent permitted by law, any liability which may arise as a result of the use of the Karista Website, its content or the information on it, 
        including links to Third Party Websites.`,
    `Where liability cannot be excluded, any liability incurred by us in relation to the use of the Karista Website or its content, is limited to the extent provided 
        for by the Australian Consumer Law. To the extent permitted by law, Karista will not be liable for any consequential, incidental, indirect or special loss.`,
    `We reserve the right to revise and update these Terms and Conditions at any time.`,
    `These Terms and Conditions are governed by the laws of Victoria, Australia. You agree to submit to the non-exclusive jurisdiction of the courts of Victoria, Australia.`
  ];

  const handleClick = () => {
    // Scroll to the top of the page
    window.scrollTo(0, 0);
  };

  const SupportButton = () => (
    <ButtonPlacementSection>
      <ButtonSection>
        <Button>
          <StyledLink onClick={handleClick} to="/">
            <ButtonBetaText>Search for supports now</ButtonBetaText>
          </StyledLink>
        </Button>
      </ButtonSection>
    </ButtonPlacementSection>
  );

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <Theme theme={themeSecondary}>
        <Navigation>
          <OrangeLink border to="/">
            Back to search
          </OrangeLink>
        </Navigation>
        <Page>
          <Section>
            <ButtonPlacementSection>
              <HeaderHeading>Karista Terms and Conditions</HeaderHeading>
            </ButtonPlacementSection>
            <NumberList>
              {paragraphs.map(paragraph => (
                <TermsListItem
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(paragraph)
                  }}
                />
              ))}
            </NumberList>
            <SupportButton />
          </Section>
        </Page>
        <Footer />
      </Theme>
    </>
  );
};
export default TermsAndConditions;
