import React from 'react';
import styled from 'styled-components';
import FaTwitter from 'react-icons/lib/fa/twitter';
import FaFacebookSquare from 'react-icons/lib/fa/facebook-square';
import FaLinkedinSquare from 'react-icons/lib/fa/linkedin-square';
import { colors } from '../../styles/constants';

const SocialContainer = styled.div`
  display: flex;
  justify-content: center;
  svg {
    padding: 0 10px;
  }
  path {
    fill: ${colors.white};
  }
  margin-top: 0.75rem;
  margin-bottom: 1rem;
`;

const Links = [
  {
    icon: <FaTwitter />,
    key: 'twitter',
    link: 'https://twitter.com/Karistacare'
  },
  {
    icon: <FaFacebookSquare />,
    key: 'facebook',
    link: 'https://www.facebook.com/Karista-413403892326876/'
  },
  {
    icon: <FaLinkedinSquare />,
    key: 'linkedin',
    link: 'https://www.linkedin.com/company/18157963/'
  }
];

const SocialLink = () => (
  <SocialContainer>
    {Links.map(({ icon, key, link }) => (
      <a key={key} href={link} target="_blank" rel="noopener noreferrer">
        {icon}
      </a>
    ))}
  </SocialContainer>
);

export default SocialLink;
